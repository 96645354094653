.add-product-btn-wrapper {
    margin: auto;
  width: 90%;
}

.product-wrapper {
  /* margin-top: 4rem; */
  /* width: 100%; */
  margin: 3rem auto;
  overflow-y: hidden;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(197, 197, 197, 0.774);
  border-radius: 10px;
  padding: 1rem 2rem;
}

.product-wrapper .product {
  display: flex;
  flex-direction: row;
  height: 120px;
  overflow: hidden;
  border-bottom: 1px solid  rgba(197, 197, 197, 0.774);
  padding: .5rem;
}

.product-image {
    min-width: 30%;
  /* width: 50%; */
  max-width: 150px !important;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.product-wrapper .product-image img {
  height: 80%;
}

.product-content {
    margin-left: 1rem;
  display: flex;
  flex-direction: column;
  /* position: relative;
  left:10px; */
  width: 50%;
  height: 90%;
}

.product-content .product-title {
    color: #000 !important;
  font-size: 16px;
  font-weight: bold;
}

.product-content .product-description {
    font-size: 14px;

  overflow: hidden;
  text-overflow: ellipsis;
  height: 80%;
  width: 100%;
}

.product-price{
    padding: .5rem;
    font-weight: bold;
    width: 150px;
    color: rgb(197, 9, 81);
}

.product-price .offer-price, .product-price .price{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

}

.product-price .offer-price span, .product-price .price span{
    font-size: 12px;

}

.product-controls{
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


@media screen and (min-width:550px) {
    .product-image {
        min-width: 150px;
      width: 150px;
      max-width: 150px ;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
}