.card1 {
    width: 380px;
    height: 120px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .card1:hover {
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
      0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
      0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  }
  @media only screen and (max-width: 500px) {
    .card1 {
      width: auto;
      height: auto;
    }
    .content {
      font-size: 15px;
    }
  }
  
  @media screen and (min-width: 750px) {
    .card1 {
      width: auto;
      height: auto;
    }
    .content {
      font-size: 15px;
    }
  }
  