.view-order-detail-wrapper{
display: flex;
flex-direction: column;
margin:1rem;
border: 1px solid grey ;
border-radius: 10px;
padding: 1rem;
}

.view-detail-menu-btn{
    float: right;
}
.view-order-detail-order-status{
    font-size: 1.4rem;
}
.view-order-customer-name{
    font-weight: bold;
    font-size: 1.3rem;
}

.view-order-detail-address{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    padding: 0 2rem;
    margin: 1rem 0;
}

.view-order-detail-address .shipping, .view-order-detail-address .billing{
    margin:1rem;
    min-width: 250px;
    width: 40%;
}

.view-order-detail-address .title{
    font-weight: 600;
    font-size: 18px;
}

.view-order-detail-address .title .address-type{
    font-size: 14px;
    color: rgb(82, 81, 81);
    background-color: rgba(175, 175, 175, 0.597);
    padding: .15rem;
    border-radius: 2px;
}

.view-order-detail-content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem;
}

.view-order-detail-content .img{
    width: 200px;

}

.view-order-detail-content .img img{
    margin:1rem;
    min-width: 150px;
    width: 100%;
}

.view-order-detail-content .content{
    margin:1rem;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;min-width: 250px;
}

.view-order-detail-content .content .product-name{
    font-size: 1.2rem;
    font-weight: bold;
}

.view-order-detail-content .content  .price{
    display: flex;
    flex-direction: row;
}

.view-order-detail-content .product-quantity{
    font-weight: bold;
    color: rgb(193, 27, 27);

    font-size: 16px;
    margin-left: 1rem;
}
.view-order-detail-product-price{
    font-weight: bold;
    color: rgb(193, 27, 27);
    font-size: 16px;
    margin-left: 1rem;

}

.view-order-detail-date .date{
    margin-left: 1rem;
    font-weight: 600;
}