.card-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.card-header {
  background-color: #e8e8f4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.card {
  margin-top: 50px;
}
.card-body {
  border-radius: 2px;
}
.rounded {
  width: 450px;
}
.card-width {
  width: 450px;
}
.container {
  columns: 2;
  width: auto;
  display: flex;
  flex-wrap: wrap;
}
