.pro-item-content{
    font-size: 12px;
}
.pro-icon-wrapper{
    width: 30px;
    height: 30px;
}

.pro-sidebar-layout{
    scrollbar-width: none;
    overflow: auto;
}