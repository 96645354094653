.muthiahs-main{
    height: 100vh;
    overflow-y: hidden;
}

.muthiahs-container{
    display: flex;
    flex-direction: row;
}


aside{
height: calc(100vh - var(--navbar-height))
}
main{
    padding:2rem .75rem 4rem;
    /* position: sticky; */
    height: 100vh;
    overflow-y: scroll;
    /* overflow-x: hidden; */
    width: 100%;
}