@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css);
@import url(https://fonts.googleapis.com/css?family=Numans);
@import url(https://fonts.googleapis.com/css?family=Numans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width: 100vw; */
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: #fff !important;
}

a:hover {
  text-decoration: none !important;
}
.f-b {
  font-weight: bold;
}

.form-error-text{
    color: red;
    font-weight: 600;
    font-size: 14px;
}

.form-error-field{
    border: 1px solid red;
}
/* variables */
:root{
  --navbar-height:56px
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.m-page-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}


.m-tab{
  color:rgb(52, 52, 52) !important
}
/* Made with love by Mutiullah Samim*/

/* html,body{
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    font-family: 'Numans', sans-serif;
} */

.my_container {
  /* background-image: url("http://getwallpapers.com/wallpaper/full/a/5/d/544750.jpg"); */
  background-image: url("https://d3rnckbcoykp.cloudfront.net/login_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Numans", sans-serif;
  height: 100vh;
}

.card {
  height: 370px;
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.login-logo {
  margin: 0 auto 2rem;
  width: 60%;
}

.login-logo img {
  width: 100%;
}

.social_icon span {
  font-size: 60px;
  margin-left: 10px;
  color: #ffc312;
}

.social_icon span:hover {
  color: white;
  cursor: pointer;
}

.card-header h3 {
  color: white;
}

.social_icon {
  position: absolute;
  right: 20px;
  top: -45px;
}

.input-group-prepend span {
  width: 50px;
  background-color: #ffc312;
  color: black;
  border: 0 !important;
}

input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
}

.remember {
  color: white;
}

.remember input {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
}

.login_btn {
  color: black;
  background-color: #ffc312;
  width: 100px;
}

.login_btn:hover {
  color: black;
  background-color: white;
}

.links {
  color: white;
}

.links a {
  margin-left: 4px;
}

/* Made with love by Mutiullah Samim*/

/* html,body{
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    font-family: 'Numans', sans-serif;
} */

.my-container {
  background-image: url("https://d3rnckbcoykp.cloudfront.net/login_background.jpg");
  background-size: cover;
    
  background-repeat: no-repeat;
  font-family: "Numans", sans-serif;
  height: 100vh;
}

.card {
  height: 370px;
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.login-logo {
  margin: 0 auto 2rem;
  width: 60%;
}

.login-logo img {
  width: 100%;
}

.social_icon span {
  font-size: 60px;
  margin-left: 10px;
  color: #ffc312;
}

.social_icon span:hover {
  color: white;
  cursor: pointer;
}

.card-header h3 {
  color: white;
}

.social_icon {
  position: absolute;
  right: 20px;
  top: -45px;
}

.input-group-prepend span {
  width: 50px;
  background-color: #ffc312;
  color: black;
  border: 0 !important;
}

input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
}

.remember {
  color: white;
}

.remember input {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
}

.login_btn {
  color: black;
  background-color: #ffc312;
  width: 100px;
}

.login_btn:hover {
  color: black;
  background-color: white;
}

.links {
  color: white;
}

.links a {
  margin-left: 4px;
}

.count-card {
  width: 220px;
  height: 120px;
  padding: 1rem;
  position: relative;
  margin: 0.75rem;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 2px 3px 16px -6px rgba(0, 0, 0, 0.75);
  overflow: hidden;
}

.count-card:hover {
  box-shadow: 3px 4px 20px 4px rgba(0, 0, 0, 0.75);
}

.count-card .title {
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
}

.count-card .count {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 3rem;
  color: #fff;
}

.count-card-wrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.add-product-wrapper{
    overflow-x: hidden;
}
.add-product-btn-wrapper {
    margin: auto;
  width: 90%;
}

.product-wrapper {
  /* margin-top: 4rem; */
  /* width: 100%; */
  margin: 3rem auto;
  overflow-y: hidden;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 1px solid rgba(197, 197, 197, 0.774);
  border-radius: 10px;
  padding: 1rem 2rem;
}

.product-wrapper .product {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 120px;
  overflow: hidden;
  border-bottom: 1px solid  rgba(197, 197, 197, 0.774);
  padding: .5rem;
}

.product-image {
    min-width: 30%;
  /* width: 50%; */
  max-width: 150px !important;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
}

.product-wrapper .product-image img {
  height: 80%;
}

.product-content {
    margin-left: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* position: relative;
  left:10px; */
  width: 50%;
  height: 90%;
}

.product-content .product-title {
    color: #000 !important;
  font-size: 16px;
  font-weight: bold;
}

.product-content .product-description {
    font-size: 14px;

  overflow: hidden;
  text-overflow: ellipsis;
  height: 80%;
  width: 100%;
}

.product-price{
    padding: .5rem;
    font-weight: bold;
    width: 150px;
    color: rgb(197, 9, 81);
}

.product-price .offer-price, .product-price .price{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 10px;

}

.product-price .offer-price span, .product-price .price span{
    font-size: 12px;

}

.product-controls{
    width: 100px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}


@media screen and (min-width:550px) {
    .product-image {
        min-width: 150px;
      width: 150px;
      max-width: 150px ;
      height: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      overflow: hidden;
    }
}
.loading-screen-main {
  position: absolute;
  top: 5%;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.loading-screen-loader,
.loading-screen-loader:before,
.loading-screen-loader:after {
  background: #000000;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  /* height: auto; */
}
.loading-screen-loader {
  color: #000000;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loading-screen-loader:before,
.loading-screen-loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loading-screen-loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loading-screen-loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.price-change-modal {
  min-height: 300px;
  padding: 10px;
}

.price-change-modal > .title {
  font-weight: bold;
  font-size: 20px;
}

th {
  font-size: 15px;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
}
td {
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.paginationBtns {
  width: 100%;
  height: 40px;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
}

.paginationBtns a {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 1px;
  padding-bottom: 2px;
  margin: 3px;
  border-radius: 20px;
  background-color: rgb(95, 68, 83);
  color: rgb(231, 13, 13);
  cursor: pointer;
  font-weight: 200px;
}

.paginationBtns a:hover {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin: 3px;
  border-radius: 20px;
  background-color: rgb(78, 53, 71);
  cursor: pointer;
}

.paginationActive a {
  background-color: rgb(16, 92, 233);
}

 .sales-order{
   width: 100%;
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
           flex-direction: row;
   padding: 1rem;
   border-bottom: 1px solid rgba(197, 197, 197, 0.774);
   min-height: 120px;
   height: auto;
 }
 
 .sales-order-product-image {
  width: 100px ;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
}

.sales-order-product-image img {
  width: 100%;
  height: 100%;
}
.add-product-btn-wrapper {
  margin: auto;
width: 90%;
}
 .sales-order-content{
   padding-left: 2rem;
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: column;
           flex-direction: column;
    width: 70%;
 }

 .sales-order-title{
   font-weight: bold;
   color: #000;
   font-size: 18px;
 }

 .sales-order-description{
   height: 60px;
   overflow: hidden;
   text-overflow: ellipsis;

 }

 .sales-order-view-btn{
   display: -webkit-flex;
   display: flex;
   -webkit-align-items: center;
           align-items: center;
 }

 .sales-order-status .status{
   font-weight: 600;
   color: rgb(186, 53, 53);
 }
.sales-order-wrapper{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}
/* body{
    background: -webkit-linear-gradient(left, #3931af, #00c6ff);
} */
.emp-profile{
    padding: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 0.5rem;
    background: #fff;
}
.profile-img{
    width: 130px;
    height: 130px;
    text-align: center;

}
.profile-img img{
    width: 100%;
    height: 100%;

}
.profile-img .file {
    position: relative;
    overflow: hidden;
    margin-top: -20%;
    width: 70%;
    border: none;
    border-radius: 0;
    font-size: 15px;
    background: #212529b8;
}
.profile-img .file input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
}
.profile-head h5{
    color: #333;
}
.profile-head h6{
    color: #0062cc;
}
.profile-edit-btn{
    border: none;
    border-radius: 1.5rem;
    width: 70%;
    padding: 2%;
    font-weight: 600;
    color: #6c757d;
    cursor: pointer;
}
.proile-rating{
    font-size: 12px;
    color: #818182;
    margin-top: 5%;
}
.proile-rating span{
    color: #495057;
    font-size: 15px;
    font-weight: 600;
}
.profile-head .nav-tabs{
    margin-bottom:5%;
}
.profile-head .nav-tabs .nav-link{
    font-weight:600;
    border: none;
}
.profile-head .nav-tabs .nav-link.active{
    border: none;
    border-bottom:2px solid #0062cc;
}
.profile-work{
    padding: 14%;
    margin-top: -15%;
}
.profile-work p{
    font-size: 12px;
    color: #818182;
    font-weight: 600;
    margin-top: 10%;
}
.profile-work a{
    text-decoration: none;
    color: #495057;
    font-weight: 600;
    font-size: 14px;
}
.profile-work ul{
    list-style: none;
}
.profile-tab label{
    font-weight: 600;
}
.profile-tab p{
    font-weight: 600;
    color: #0062cc;
}

.card-items {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.card-header {
  background-color: #e8e8f4;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.card {
  margin-top: 50px;
}
.card-body {
  border-radius: 2px;
}
.rounded {
  width: 450px;
}
.card-width {
  width: 450px;
}
.container {
  -webkit-columns: 2;
          columns: 2;
  width: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

#view
{
    width:100%;
    margin: 1em 0;
  border-collapse: collapse;
  border: 0.1em solid #d6d6d6;
  

}
.row-header{
  
    vertical-align: bottom;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  

    
}
.data
{
    padding-left: 40px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding: 0.25em 0.5em 0.25em 1em;
  vertical-align: text-top;
  text-align: left;
  text-indent: -0.5em;

}


.tablerow
{
    height:30px;
}
.tablerow:nth-child(even) th[scope=row] {
    background-color: #f2f2f2;
  }
.tablerow:nth-child(odd) th[scope=row] {
    background-color: #fff;
  }
.tablerow:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.05);
  } 
.tablerow:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .data:nth-of-type(2) {
    font-style: italic;
  }
  
  .row-header:nth-of-type(3),
  .data:nth-of-type(3) {
    text-align: right;
  }
  
.nav-bar-wrapper {
  /* width: 100%; */
  background-color: #1d1d1d;
  font-weight: 600;
  font-size: 16px;
  z-index: 2;
  height: var(--navbar-height);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.nav-bar-menu-icon {
  width: 50px;
  color: #fff;
  position: relative;
  /* top: 15px; */
  left: 20px;
}
.nav-bar-brand {
  /* width: 130px; */
  height: 70%;
}

.nav-bar-brand img {
  height: 100%;
}
.nav-bar-profile-icon {
  color: #fff;
  position: absolute;
  right: 25px;
}

ul {
  margin-bottom: 0 !important;
}

.nav-list {
  list-style-type: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 1rem;
}

.nav-list li {
  margin: 0 1rem;
}

.pro-item-content{
    font-size: 12px;
}
.pro-icon-wrapper{
    width: 30px;
    height: 30px;
}

.pro-sidebar-layout{
    scrollbar-width: none;
    overflow: auto;
}
.muthiahs-main{
    height: 100vh;
    overflow-y: hidden;
}

.muthiahs-container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}


aside{
height: calc(100vh - var(--navbar-height))
}
main{
    padding:2rem .75rem 4rem;
    /* position: sticky; */
    height: 100vh;
    overflow-y: scroll;
    /* overflow-x: hidden; */
    width: 100%;
}
.view-order-detail-wrapper{
display: -webkit-flex;
display: flex;
-webkit-flex-direction: column;
        flex-direction: column;
margin:1rem;
border: 1px solid grey ;
border-radius: 10px;
padding: 1rem;
}

.view-detail-menu-btn{
    float: right;
}
.view-order-detail-order-status{
    font-size: 1.4rem;
}
.view-order-customer-name{
    font-weight: bold;
    font-size: 1.3rem;
}

.view-order-detail-address{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    /* justify-content: space-between; */
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 0 2rem;
    margin: 1rem 0;
}

.view-order-detail-address .shipping, .view-order-detail-address .billing{
    margin:1rem;
    min-width: 250px;
    width: 40%;
}

.view-order-detail-address .title{
    font-weight: 600;
    font-size: 18px;
}

.view-order-detail-address .title .address-type{
    font-size: 14px;
    color: rgb(82, 81, 81);
    background-color: rgba(175, 175, 175, 0.597);
    padding: .15rem;
    border-radius: 2px;
}

.view-order-detail-content{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 1rem;
}

.view-order-detail-content .img{
    width: 200px;

}

.view-order-detail-content .img img{
    margin:1rem;
    min-width: 150px;
    width: 100%;
}

.view-order-detail-content .content{
    margin:1rem;
    padding-left: 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;min-width: 250px;
}

.view-order-detail-content .content .product-name{
    font-size: 1.2rem;
    font-weight: bold;
}

.view-order-detail-content .content  .price{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.view-order-detail-content .product-quantity{
    font-weight: bold;
    color: rgb(193, 27, 27);

    font-size: 16px;
    margin-left: 1rem;
}
.view-order-detail-product-price{
    font-weight: bold;
    color: rgb(193, 27, 27);
    font-size: 16px;
    margin-left: 1rem;

}

.view-order-detail-date .date{
    margin-left: 1rem;
    font-weight: 600;
}
.nav_bar_dashboard{
    background-color: #476392;
  font-weight: 600;
  font-size: 16px;
  z-index: 2;
  height: var(--navbar-height);
}

.navbar_logo_height{
    height: var(--navbar-height);
    vertical-align: middle;
    /* position: absolute; */
}
/* .navbar_logo_height img{
    position: relative;
    top: 50%;
    left: 50%;
} */
.landertext {
  position: relative;
  z-index: 1;
}
.landertext .maincontent {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(/static/media/ireland-1985088_19201.30db0152.jpg) center center;
  background-size: cover;
  opacity: 0.7;
  width: 100%;
  height: 1000px;
}

@media (min-width: 360px) and (max-width: 767.98px) {
  .image {
    margin-top: 100px;
    height: 70px;
    width: 180px;
  }
  .landerh1 {
    font-size: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .image {
    margin-top: 100px;
    height: 80px;
    width: 280px;
  }
  .landerh1 {
    font-size: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1300px) {
  .image {
    margin-top: 100px;
    height: 100px;
    width: 320px;
  }
  .landerh1 {
    font-size: 40px;
  }
}

* {
  font-family: "Open Sans", sans-serif;
}

a {
  color: #47b2e4;
  text-decoration: none;
}

a:hover {
  color: #73c5eb;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Jost", sans-serif;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  list-style: none;
  -webkit-align-items: center;
          align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #47b2e4;
}

.navbar .getstarted {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  border: 2px solid #47b2e4;
  font-weight: 600;
}

.navbar .getstarted:hover {
  color: #fff;
  background: #31a9e1;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  color: #0c3c53;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #47b2e4;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(40, 58, 90, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a {
  padding: 10px 20px;
  font-size: 15px;
  color: #37517e;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #47b2e4;
}

.navbar-mobile .getstarted {
  margin: 15px;
  color: #37517e;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #47b2e4;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 80vh;
  background: #37517e;
}

#hero .container {
  padding-top: 72px;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
}

#hero h2 {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 50px;
  font-size: 24px;
}

#hero .btn-get-started {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px 11px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px 0 0 0;
  color: #fff;
  background: #47b2e4;
}

#hero .btn-get-started:hover {
  background: #209dd8;
}

#hero .btn-watch-video {
  font-size: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  transition: 0.5s;
  margin: 10px 0 0 25px;
  color: #fff;
  line-height: 1;
}

#hero .btn-watch-video i {
  line-height: 0;
  color: #fff;
  font-size: 32px;
  transition: 0.3s;
  margin-right: 8px;
}

#hero .btn-watch-video:hover i {
  color: #47b2e4;
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 991px) {
  #hero {
    height: 100vh;
    text-align: center;
  }
  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }
  #hero .hero-img {
    text-align: center;
  }
  #hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
  #hero .btn-get-started {
    font-size: 16px;
    padding: 10px 24px 11px 24px;
  }
}

@-webkit-keyframes up-down {
  0% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f3f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #37517e;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
  text-align: justify;
}
/* .about .content {
  margin-left: 70px;
} */
.about .content {
  text-align: justify;
}

.about h2 {
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li + li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #47b2e4;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .btn-learn-more {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 4px;
  transition: 0.3s;
  line-height: 1;
  color: #47b2e4;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #47b2e4;
}

.about .content .btn-learn-more:hover {
  background: #47b2e4;
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us .content {
  padding: 60px 100px 0 100px;
  text-align: justify;
}

.why-us .content h3 {
  font-weight: 400;
  font-size: 34px;
  color: #37517e;
}

.why-us .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.why-us .content p {
  font-size: 15px;
  color: #848484;
}

.why-us .img,
.about .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.why-us .accordion-list {
  padding: 0 100px 60px 100px;
}

.why-us .accordion-list ul {
  padding: 0;
  list-style: none;
}

.why-us .accordion-list li + li {
  margin-top: 15px;
}

.why-us .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.why-us .accordion-list a {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  cursor: pointer;
}

.why-us .accordion-list span {
  color: #47b2e4;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.why-us .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.why-us .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.why-us .accordion-list .icon-show {
  display: none;
}

.why-us .accordion-list a.collapsed {
  color: #343a40;
}

.why-us .accordion-list a.collapsed:hover {
  color: #47b2e4;
}

.why-us .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.why-us .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {
  .why-us .content,
  .why-us .accordion-list {
    padding-left: 0;
    padding-right: 0;
    text-align: justify;
  }
}

@media (max-width: 1300px) {
  .why-us .content {
    min-height: 380px;
  }
}
@media (max-width: 1300px) {
  .about .content {
    padding: 50px 100px 0px 70px;
  }
}

@media (max-width: 992px) {
  .why-us .img,
  .about .img {
    min-height: 400px;
  }
  .why-us .content {
    padding-top: 30px;
    text-align: justify;
  }
  .why-us .accordion-list {
    padding-bottom: 30px;
  }
  .about .content {
    padding: 10px 0px 0px 70px;
  }
}

@media (max-width: 1300px) {
  .about .img {
    min-height: 270px;
    /* margin-left: 70px; */
  }
}

@media (max-width: 575px) {
  .why-us .img,
  .about .img {
    min-height: 200px;
  }
  .about .content {
    padding: 10px 0px 0px 10px;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  transition: all ease-in-out 0.4s;
  background: #fff;
}

.services .icon-box .icon {
  margin-bottom: 10px;
}

.services .icon-box .icon i {
  color: #47b2e4;
  font-size: 36px;
  transition: 0.3s;
}

.services .icon-box h4 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #37517e;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.services .icon-box:hover h4 a {
  color: #47b2e4;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  font-size: 14px;
  background: #37517e;
}

#footer .footer-newsletter {
  padding: 50px 0;
  background: #f3f5fa;
  text-align: center;
  font-size: 15px;
  color: #444444;
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  color: #37517e;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
  text-align: left;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #47b2e4;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #209dd8;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #fff;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 28px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 600;
  color: #37517e;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Jost", sans-serif;
  color: #5e5e5e;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #37517e;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #47b2e4;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #777777;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #47b2e4;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #47b2e4;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #209dd8;
  color: #fff;
  text-decoration: none;
}

#footer .footer-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
}

#footer .copyright {
  float: left;
}

#footer .credits {
  float: right;
  font-size: 13px;
}

#footer .credits a {
  transition: 0.3s;
}

@media (max-width: 768px) {
  #footer .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #footer .copyright,
  #footer .credits {
    text-align: center;
    float: none;
  }
  #footer .credits {
    padding-top: 4px;
  }
}

.card-content
{
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
}

.card1 {
    width: 380px;
    height: 120px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .card1:hover {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
      0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
      0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  }
  @media only screen and (max-width: 500px) {
    .card1 {
      width: auto;
      height: auto;
    }
    .content {
      font-size: 15px;
    }
  }
  
  @media screen and (min-width: 750px) {
    .card1 {
      width: auto;
      height: auto;
    }
    .content {
      font-size: 15px;
    }
  }
  
.jobdes {
  height: auto;
}

h5,
p {
  font-family: "Helvetica Neue, Helvetica, Arial, sans-serif";
}
h5 {
  font-weight: bold;
}
@media only screen and (max-width: 500px) {
  .jobdes {
    width: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

.form-control {
  overflow: hidden;
}
.form-control:focus
{
  border: 2px solid royalblue;
}
/* phone input */
:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.apply_banner{
  border-radius: 20px ;
  overflow: hidden;
  
}
.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* padding-top: 20px; */
  /* padding-left: 15px; */
  width: auto;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  -webkit-flex: 1 1;
          flex: 1 1;
  border: none;

  /* The phone number input should shrink
         to make room for the extension input */

  /* min-width: 0; */
  /* height: 3rem; */
  /* padding-left: 1.0625rem; */
  /* font-family: OpenSans, sans-serif; */
  /* font-weight: 400; */
  /* position: relative; */
  /* left:100px; */
  /* font-style: normal; */
  /* font-size: 1.125rem; */
  /* color: #484553; */
  /* background-color: #f4f3f3; */
  /* border-radius: 1px; */
  /* border: 0.5px solid rgb(206, 205, 205); */
}

.PhoneInputCountryIcon {
  width: calc(
    1em *
      1.5);
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: 1em;
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: 1em;
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
         it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
         and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: rgba(0, 0, 0, 0.1);
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
         and sometime there can be seen white pixels of the background at top and bottom,
         so an additional "inset" border is added. */
  box-shadow: 0 0 0 1px
      rgba(0, 0, 0, 0.5),
    inset 0 0 0 1px
      rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
         Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: 0.8;
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: 0.65;
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
  position: relative;
  -webkit-align-self: stretch;
          align-self: stretch;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 0.35em;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: 0.3em;
  width: var(--PhoneInputCountrySelectArrow-width);
  height: 0.3em;
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: 0.35em;
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: inherit;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: 1px;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: 1px;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform: var(--PhoneInputCountrySelectArrow-transform);
          transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: 0.45;
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: #03b2cb;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 1px
      #03b2cb,
    inset 0 0 0 1px
      #03b2cb;
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: #03b2cb;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

/*  */

/* remove padding */
.country-container{
  /* margin: auto !important; */
  padding-right: -12px !important;
}
.apply-form-row{
  padding: 0 !important;
  /* padding-left: 12px !important; */
}

