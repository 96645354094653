 .sales-order{
   width: 100%;
   display: flex;
   flex-direction: row;
   padding: 1rem;
   border-bottom: 1px solid rgba(197, 197, 197, 0.774);
   min-height: 120px;
   height: auto;
 }
 
 .sales-order-product-image {
  width: 100px ;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.sales-order-product-image img {
  width: 100%;
  height: 100%;
}
.add-product-btn-wrapper {
  margin: auto;
width: 90%;
}
 .sales-order-content{
   padding-left: 2rem;
   display: flex;
   flex-direction: column;
    width: 70%;
 }

 .sales-order-title{
   font-weight: bold;
   color: #000;
   font-size: 18px;
 }

 .sales-order-description{
   height: 60px;
   overflow: hidden;
   text-overflow: ellipsis;

 }

 .sales-order-view-btn{
   display: flex;
   align-items: center;
 }

 .sales-order-status .status{
   font-weight: 600;
   color: rgb(186, 53, 53);
 }