.count-card {
  width: 220px;
  height: 120px;
  padding: 1rem;
  position: relative;
  margin: 0.75rem;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  -webkit-box-shadow: 2px 3px 16px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 3px 16px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 3px 16px -6px rgba(0, 0, 0, 0.75);
  overflow: hidden;
}

.count-card:hover {
  -webkit-box-shadow: 3px 4px 20px 4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 4px 20px 4px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 4px 20px 4px rgba(0, 0, 0, 0.75);
}

.count-card .title {
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
}

.count-card .count {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 3rem;
  color: #fff;
}
