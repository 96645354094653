th {
  font-size: 15px;
  text-align: center;
  justify-content: center;
}
td {
  text-align: center;
  justify-content: center;
}
.paginationBtns {
  width: 100%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.paginationBtns a {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 1px;
  padding-bottom: 2px;
  margin: 3px;
  border-radius: 20px;
  background-color: rgb(95, 68, 83);
  color: rgb(231, 13, 13);
  cursor: pointer;
  font-weight: 200px;
}

.paginationBtns a:hover {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin: 3px;
  border-radius: 20px;
  background-color: rgb(78, 53, 71);
  cursor: pointer;
}

.paginationActive a {
  background-color: rgb(16, 92, 233);
}
