.nav_bar_dashboard{
    background-color: #476392;
  font-weight: 600;
  font-size: 16px;
  z-index: 2;
  height: var(--navbar-height);
}

.navbar_logo_height{
    height: var(--navbar-height);
    vertical-align: middle;
    /* position: absolute; */
}
/* .navbar_logo_height img{
    position: relative;
    top: 50%;
    left: 50%;
} */