#view
{
    width:100%;
    margin: 1em 0;
  border-collapse: collapse;
  border: 0.1em solid #d6d6d6;
  

}
.row-header{
  
    vertical-align: bottom;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  

    
}
.data
{
    padding-left: 40px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding: 0.25em 0.5em 0.25em 1em;
  vertical-align: text-top;
  text-align: left;
  text-indent: -0.5em;

}


.tablerow
{
    height:30px;
}
.tablerow:nth-child(even) th[scope=row] {
    background-color: #f2f2f2;
  }
.tablerow:nth-child(odd) th[scope=row] {
    background-color: #fff;
  }
.tablerow:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.05);
  } 
.tablerow:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .data:nth-of-type(2) {
    font-style: italic;
  }
  
  .row-header:nth-of-type(3),
  .data:nth-of-type(3) {
    text-align: right;
  }
  