.jobdes {
  height: auto;
}

h5,
p {
  font-family: "Helvetica Neue, Helvetica, Arial, sans-serif";
}
h5 {
  font-weight: bold;
}
@media only screen and (max-width: 500px) {
  .jobdes {
    width: auto;
    height: fit-content;
  }
}
