.nav-bar-wrapper {
  /* width: 100%; */
  background-color: #1d1d1d;
  font-weight: 600;
  font-size: 16px;
  z-index: 2;
  height: var(--navbar-height);
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.nav-bar-menu-icon {
  width: 50px;
  color: #fff;
  position: relative;
  /* top: 15px; */
  left: 20px;
}
.nav-bar-brand {
  /* width: 130px; */
  height: 70%;
}

.nav-bar-brand img {
  height: 100%;
}
.nav-bar-profile-icon {
  color: #fff;
  position: absolute;
  right: 25px;
}

ul {
  margin-bottom: 0 !important;
}

.nav-list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  padding: 1rem;
}

.nav-list li {
  margin: 0 1rem;
}
